<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card mt-2">
        <h2 class="text-center mt-2">Dynamic AX</h2>
        <div class="card-body">
          <b-row v-if="isError" class="justify-content-center mt-2">
            <b-col lg="10">
              <b-alert variant="danger" show dismissible>
                Opps .. Something is wrong
              </b-alert>
            </b-col>
          </b-row>
          <b-row v-if="errorMessage" class="justify-content-center mt-2">
            <b-col lg="10">
              <b-alert variant="danger" 
                dismissible
                :show="dismissCountDown"
                @dismissed="dismissCountDown=0"
                @dismiss-count-down="countDownChanged">
                {{  errorMessage }}
              </b-alert>
            </b-col>
          </b-row>
          <div class="row justify-content-md-center">
            <div class="col-sm-2">
              <b-form-select
                id="currency"
                class="mb-2"
                v-model="itemStatus"
                :options="statusOptions"
                required
                @change="onItemStatusChange"
              ></b-form-select>
            </div>
            <div class="col-sm-5">
              <input
                type="text"
                v-model="searchForm"
                class="form-control"
                placeholder="search by prodcut name | vendor name | pis number | dynamic id"
                @input="debounceSearch"
              />
              <span v-if="typing"> <em> searching... </em> </span>
            </div>
            <div class="col-sm-1" v-if="isLoadingRetry">
              <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          </div>
          <div class="row justify-content-end">
            <label for="" class="col-sm-auto font-weight-bold">Total :</label>
            <strong class="col-sm-auto">{{ formatNumber(totalRows) }}</strong>
          </div>
          <b-table
            sticky-header="500px"
            :isLoading="isLoading"
            responsive="sm"
            show-empty
            striped
            :busy="isLoading"
            :fields="fields"
            hover
            :items="items"
            @sort-changed="sortingChanged" 
          >
            <template #cell(No)="row">
              {{ (currentPage - 1) * perPage + (row.index + 1) }}.
            </template>
            <template #cell(product_name)="row">
              <div style="width: 250px">
                <router-link
                  :to="{
                    path:
                      '/items/detail/' +
                      row.item?.external_itemid?.toString().replace(/\D/g, ''),
                  }"
                  class="link"
                >
                  {{ row.item.product_name }}
                </router-link>
              </div>
            </template>
            <template #cell(external_itemid)="row">
              <div style="width: 120px" class="text-center">
                <span class="badge badge-primary">
                  {{
                    row.item.external_itemid ? row.item.external_itemid : '-'
                  }}
                </span>
              </div>
            </template>
            <template #cell(discount)="row">
              <div style="width: 120px" class="text-center">
                {{ row.item.discount ? row.item.discount : '-' }}
              </div>
            </template>
            <template #cell(vendor_name)="row">
              <div style="width: 250px">
                {{ row.item.vendor_name ? row.item.vendor_name : '-' }}
              </div>
            </template>
            <template #cell(vendor_account)="row">
              <div style="width: 150px">
                {{ row.item.vendor_account ? row.item.vendor_account : '-' }}
              </div>
            </template>
            <template #cell(status_item)="row">
              <div style="width: 100px">
                <span
                  class="badge badge-secondary"
                  v-if="row.item.status_item == '0'"
                >
                  Submitted
                </span>
                <span
                  class="badge badge-purple"
                  v-if="row.item.status_item == '1'"
                >
                  Approved
                </span>
                <span
                  class="badge badge-primary"
                  v-if="row.item.status_item == '2'"
                >
                  Released
                </span>
                <span
                  class="badge badge-red"
                  v-if="row.item.status_item == '3'"
                >
                  Rejected
                </span>
                <span
                  class="badge badge-red"
                  v-if="row.item.status_item == '10'"
                >
                  Rejected
                </span>
              </div>
            </template>
            <template #cell(pisnumber)="row">
              <div style="width: 100px">
                {{ row.item.pisnumber ? row.item.pisnumber : '-' }}
              </div>
            </template>
            <template #cell(dynamics_id)="row">
              <div style="width: 100px">
                {{ row.item.dynamics_id ? row.item.dynamics_id : '-' }}
              </div>
            </template>
            <template #cell(status_message)="row">
              <div style="width: 200px">
                {{ row.item.status_message ? row.item.status_message : '-' }}
              </div>
            </template>
            <template #cell(releasedate_dymamics)="row">
              <div style="width: 150px">
                {{
                  row.item.releasedate_dymamics
                    ? epochConvert(parseInt(row.item.releasedate_dymamics))
                    : '-'
                }}
              </div>
            </template>
            <template #cell(created)="row">
              <div style="width: 250px">
                {{ row.item.created ? formatDate(row.item.created) : '-' }}
              </div>
            </template>
            <template #cell(modified)="row">
              <div style="width: 250px">
                {{ row.item.modified ? formatDate(row.item.modified) : '-' }}
              </div>
            </template>
            <template #cell(action)="row">
              <div class="btn-group" role="group" aria-label="Basic example">
                <button
                  type="button"
                  size="sm"
                  @click="row.toggleDetails"
                  class="btn btn-sm btn-outline-dark"
                >
                  {{ row.detailsShowing ? 'Hide' : 'Detail' }}
                </button>
              </div>
            </template>
            <template #row-details="row">
              <div v-if="typeof row.item.details == 'object'">
                <pre>{{ JSON.stringify(row.item.details, null, 2) }}</pre>
              </div>
              <div v-else>
                <pre>{{ objectString(JSON.parse(row.item.details)) }}</pre>
              </div>
            </template>

            <template #cell(retry)="row">
              <button class="btn btn-sm btn-outline-danger"
                v-if="row.item.status_item == rejected || row.item.status_item ==10"
                :disabled="isLoadingRetry"
                @click="resendItem(row.item?.id)">
                <i class="fa fa-arrow-right"></i> Resend
              </button>
            </template>
            <template #cell(edit)="row">
              <router-link
                v-if="row.item.status_item == rejected || row.item.status_item ==10"
                :to="{
                  path: `/ax/items/register-item-ax/${row.item.id}`
                }"
                class="btn btn-sm btn-outline-primary"
              >
                <i class="fa fa-pencil"></i>
                Edit
              </router-link>
            </template>

            <template v-slot:table-busy>
              <div class="text-center text-dark my-2">
                <b-spinner
                  class="align-middle"
                  variant="dark"
                  type="grow"
                  label="Loading .."
                >
                </b-spinner>
                Loading ..
              </div>
            </template>
          </b-table>
        </div>
        <b-row class="justify-content-start ml-1">
          <b-col lg="3">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              aria-controls="my-table"
            />
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapState, mapActions } from 'vuex';
import constant from '../../store/constant';
import API from '../../plugins/http';
const itemStatus = Object.entries(constant.ITEM_STATUS_REGISTER_AX).map(
  (val) => ({
    value: val[1],
    text: val[0],
  }),
);
export default {
  name: 'RegisterItemAx',
  data() {
    return {
      rejected: constant.ITEM_STATUS_REGISTER_AX.Rejected,
      currentPage: 1,
      perPage: 50,
      fields: [
        { key: 'No', label: 'No' },
        { key: 'product_name', label: 'Product Name' },
        { key: 'external_itemid', label: 'External Item ID' },
        { key: 'discount', label: 'Discount (GAM)' },
        { key: 'vendor_name', label: 'Vendor Name' },
        { key: 'vendor_account', label: 'Vendor Account' },
        { key: 'status_item', label: 'Status Item' },
        { key: 'pisnumber', label: 'PIS Number' ,sortable: true},
        { key: 'dynamics_id', label: 'Dynamic ID'},
        { key: 'status_message', label: 'Status Message' },
        { key: 'releasedate_dymamics', label: 'Release Date' },
        { key: 'created', label: 'created' },
        { key: 'modified', label: 'modified',sortable: true },
        { key: 'action', label: '' },
        { key: 'retry', label: 'Retry' },
        { key: 'edit', label: 'Edit'}
      ],
      typing: '',
      searchForm: null,
      itemStatus: '',
      statusOptions: [
        {
          text: 'All Item Status',
          value: '',
        },
        ...itemStatus,
      ],
      isLoadingRetry: false,
      errorMessage: '',
      dismissSecs: 5,
      dismissCountDown: 5,
    };
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.registerAx.isLoading,
      isError: (state) => state.registerAx.isError,
      totalRows: (state) => state.registerAx.totalRows,
      items: (state) => state.registerAx.items,
    }),
  },
  watch: {
    currentPage() {
      this.fetchData();
    },
    $route: {
      immediate: true,
      handler(to) {
        document.title =
          to.meta.title ||
          'Dynamic Item AX | Content Management System Ebooks Gramedia.com';
      },
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    ...mapActions('registerAx', ['fetchAllRegisterAx']),
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    sortingChanged(ctx){
      console.log('sorting changed',ctx.sortBy,ctx.sortDesc)
      let payload = {
        page: this.currentPage,
        limit: this.perPage,
        sort: ctx.sortBy,
        order: ctx.sortDesc ? 'desc' : 'asc'
      };
      if (this.searchForm) payload.q = this.searchForm;
      if (this.itemStatus || this.itemStatus == '0')
        payload.status_item = this.itemStatus;
      this.fetchAllRegisterAx(payload);
    },
    fetchData() {
      let payload = {
        page: this.currentPage,
        limit: this.perPage,
      };
      if (this.searchForm) payload.q = this.searchForm;
      if (this.itemStatus || this.itemStatus == '0')
        payload.status_item = this.itemStatus;
      this.fetchAllRegisterAx(payload);
    },
    formatDate(date) {
      return new Date(date).toString();
    },
    epochConvert(date) {
      return moment.unix(date).format('YYYY-MM-DD HH:mm:ss');
    },
    formatTimeinfo(date) {
      return moment(date).fromNow();
    },
    formatNumber(value) {
      if (value) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
      }
      return '0';
    },
    debounceSearch(event) {
      this.typing = 'You are typing';
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.typing = null;
        this.message = event.target.value;
        this.fetchData();
      }, 600);
    },
    onItemStatusChange() {
      this.fetchData();
    },
    objectString(data) {
      return JSON.stringify(data, null, 2);
    },
    resendItem(id) {
      this.isLoadingRetry = true;
      API.post('dynamic-item-ax/retry', { id })
        .then((res) => {
          if (res?.status == 200) {
            this.alertMsg(res?.data?.data, 'success');
          }
          this.fetchData()
        })
        .catch((err) => {
          this.errorMessage = err?.response?.data?.data || 'Failed to resend item, please try again later';
          this.countDownChanged(5)
        })
        .finally(() => {
          this.isLoadingRetry = false;
        })
    },
    alertMsg(message, type) {
      this.$swal({
        icon: type,
        toast: true,
        position: 'top-end',
        title: message,
        timer: 5000,
      });
    },
  },
};
</script>
<style scoped>
input::placeholder {
  font-style: italic;
}
.badge-purple {
  background-color: #ff06dc;
}
.badge-blue {
  background-color: #1c51c2;
  color: rgb(255, 255, 255);
}
.badge-lightblue {
  background-color: #10d7c0;
  color: black;
}
.badge-red {
  background-color: #d80d25;
  color: rgb(254, 254, 254);
}
</style>
